@import './../../../../scss/theme-bootstrap';

.product-replenishment-select {
  padding: 0 0 15px 0;
  font-size: 16px;
  &__options {
    display: none;
    padding: 10px 0;
    .product-full &,
    .product-quickshop & {
      @include breakpoint($medium-up) {
        width: 60%;
      }
    }
  }
  &__option {
    align-content: center;
    align-items: center;
    display: flex;
    gap: 8px;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-bottom: 12px;
    width: 100%;
    label {
      display: inline-block;
      margin-#{$ldirection}: 20px;
    }
    input[type='radio'] {
      accent-color: $color--black;
      height: 24px;
      #{$ldirection}: unset;
      position: absolute;
      width: 24px;
      ~label {
        &::before {
          content: '';
        }
      }
    }
  }
  .product-replenishment__icon {
    cursor: pointer;
  }
}
